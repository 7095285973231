import axios from 'axios'
import Cookies from 'js-cookie'
import { tryCatch, pipe } from 'rambda'

let userData
let getQueue = []
let site
let isWhitelabel
const host = window.location.origin
let baseStandaloneHost = host
let baseCallbackUri
let baseLogoutUri

function addQueryParam(url, key, value) {
	// Check if the URL already has query parameters
	const separator = url.includes('?') ? '&' : '?';
	// Add the new query parameter to the URL
	return `${url}${separator}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

function getSecondLevelDomain() {
	const hostname = window.location.hostname;  // e.g., 'www.examplesit.com.au' or 'www.example.com.au'
	const parts = hostname.split('.');

	// Start with the second part of the domain
	let secondLevelDomain = parts[1];

	// Remove 'sit' or 'uat' if they are present at the end
	secondLevelDomain = secondLevelDomain.replace(/(sit|uat)$/, '');

	return secondLevelDomain;  // This will be 'example'
}

// Initialise the `User` module
function init(siteData) {
	let siteRampart
	site = siteData.is_for_bss ? 'buysearchsell' : 'mytributes'
	isWhitelabel = siteData.id !== '94' && siteData.id !== '96'

	if (siteData.environment === 'production') {
		baseStandaloneHost = `https://www.${site}.com.au`
	} else if (siteData.environment === 'staging') {
		baseStandaloneHost = `https://www.${site}uat.com.au`
	} else if (siteData.environment === 'sit') {
		baseStandaloneHost = `https://www.${site}sit.com.au`
	} else if (siteData.environment === 'localdev') {
		baseStandaloneHost = `https://www.${site}.localhost`
	}
	baseCallbackUri = `${baseStandaloneHost}/auth0/callback/`
	baseLogoutUri = `${baseStandaloneHost}/auth0/logout/`

	/* Initialise Rampart via CDN */
	if (typeof Rampart === 'undefined') {
		// Request user data from server and not rampart
		requestUserData(siteData)
		return console.info('Rampart is not available!')
	}

	if (isWhitelabel) {
		siteRampart = {
			site: getSecondLevelDomain(),
		}
	} else {
		siteRampart = {
			site: site,
			callbackUri: baseCallbackUri,
			logoutUri: baseLogoutUri,
		}
	}

	const rampart = new window.Rampart(siteRampart)

	// Prevent further modification or re-initialization of the Rampart instance
	Object.defineProperty(window, 'auth', {
		get: () => rampart,
		set: () => { console.warn('Attempt to re-initialize the "auth" property has been prevented.') },
		configurable: false
	})

	// Request user data and continue with the page load
	requestUserData(siteData)
}

// Takes a `Fn` and when the user data is ready, returns `UserData`
const get = (callback) => userData ? callback(userData) : getQueue.push(callback)

const setupLogin = (loginButton) => {
	loginButton?.addEventListener('click', async () => {
		const redirectUri = isWhitelabel ? addQueryParam(`${window.location.href}`, 'forceLogin', 'true') : window.location.href
		const callbackUri = `${baseCallbackUri}?redirectUri=${redirectUri}`

		if (typeof Rampart === 'undefined') {
			console.info('Rampart is not available!')
			if (rnn.site.isDebug) {
				window.location.href = `${baseStandaloneHost}/auth0/login-debug/?redirectUri=${redirectUri}`
			}
		} else {
			// Re-initialize the Rampart instance with the correct site to log into
			const rampart = new window.Rampart({
				site: site,
				callbackUri: `${baseCallbackUri}`,
				logoutUri: `${baseLogoutUri}`,
			})

			// Check if a user is already logged In and get user's Profile
			// getLoginStatus returns a Promise
			rampart.getLoginStatus()
				.then(function (payload) {
					if (payload.status === 'loggedin') {
						userData = payload
						clearGetQueue()
						// The user is logged in, go straight to the callbackUri
						window.location.href = callbackUri
					} else {
						//User not logged in, go to login.
						rampart.login(redirectUri)
					}
				})
				.catch(() => {
					// User not logged in, go to login.
					rampart.login(redirectUri)
				})
		}
	})
}

const setupLogout = (logoutButton) => {
	logoutButton?.addEventListener('click', async () => {
		// to redirect users to homepage if user logout from myaccount page
		let returnUrl = window.location.href
		if (returnUrl.includes('/myaccount/')) {
			returnUrl = window.location.origin

			if (isWhitelabel) {
				returnUrl = window.rnn.site.is_for_bss ? `${returnUrl}/classifieds/` : `${returnUrl}/tributes/`
			}
		}

		if (typeof window.auth === 'undefined' && window.rnn.site.isDebug) {
			window.location.href = `${baseLogoutUri}?returnTo=${returnUrl}`
		}
		if (isWhitelabel) {
			window.auth.ssoLogout(returnUrl)
		} else {
			window.auth.logout(returnUrl)
		}
	})
}

function clearGetQueue() {
	let callback

	while (getQueue.length > 0) {
		callback = getQueue.pop()
		callback(userData)
		callback = null
	}
}

// Safely parse the user data, if we throw an error return `{}`
const parseUserData = tryCatch(pipe(atob, decodeURIComponent, JSON.parse), {})

// Returns the current `UserData` from a cookie, or an empty object
export const cookieUserData = () => parseUserData(Cookies.get('user_data'))

async function requestUserData(siteData) {
	let forceLogin = false

	// If we on White label sites and forceLogin is in the URL, force the user to log in
	// Get the current URL
	const url = new URL(window.location.href)

	// Check if the 'forceLogin' parameter exists
	if (url.searchParams.has('forceLogin')) {
		forceLogin = true

		// Remove 'forceLogin' from the query parameters
		url.searchParams.delete('forceLogin')

		// Update the browser's URL without reloading the page
		window.history.replaceState(null, '', url.toString())
	}

	if (typeof window.auth !== 'undefined') {
		// Check if a user is already logged in and get user's profile
		window.auth.getLoginStatus()
			.then(function (payload) {
				if (payload.status === 'loggedin') {
					userData = payload
					clearGetQueue()
				} else if (forceLogin) {
					//User not logged in, go to login.
					window.auth.login(url.toString())
				}
			})
			.catch(() => {
				if (forceLogin) {
					// User not logged in, go to login.
					window.auth.login(url.toString())
				}
			})
	}

	// Only want to trigger the userstatus request if the current site has a my-account URL set up
	// - currently configured by setting rnn.site.userAccountURL
	if (!userData && siteData.userAccountURL) {
		const response = await axios.get(siteData.userDataURL)
		userData = response.data // data?
		clearGetQueue()
	}
}

// isSubscriber :: () => Boolean
export const isSubscriber = () => Cookies.get('subscribed') === 'true' || cookieUserData().subscribed

const checkLoginUser = (isTributes) => {
	setupLogin(document.getElementById('login'))
	setupLogout(document.getElementById('logout'))

	get(isTributes ? showMYTProfileMenu : showBSSProfileMenu)
}

const showBSSProfileMenu = (user) => {
	if (user.status === 'loggedin' || user.authenticated === true) {
		document.querySelectorAll('.authenticated')?.forEach((element) => element.classList.remove('authenticated'))
		document.querySelector('.unauthenticated')?.remove()

		const userNameElement = document.querySelector('.authenticated-user-first-name')
		if (userNameElement) userNameElement.innerText = user.name || user.first_name

		if (user.picture) {
			const userImageElement = document.querySelector('.authenticated-user-avatar img')
			if (userImageElement) userImageElement.src = decodeURIComponent(user.picture)
		}
	} else {
		document.querySelector('.unauthenticated')?.classList.remove('unauthenticated')
		document.querySelectorAll('.authenticated')?.forEach((element) => element.remove())
	}
}

const showMYTProfileMenu = (user) => {
	if (user.status === 'loggedin' || user.authenticated === true) {
		document.querySelector('.btn-login-signup')?.classList.add('inactive')
		document.querySelector('.profile-greeting')?.classList.remove('inactive')

		const profileGreetingTextElement = document.querySelector('.profile-greeting .authenticated-profile-greeting-text')
		if (profileGreetingTextElement) profileGreetingTextElement.textContent = `Hi, ${user.name || user.first_name}`
	} else {
		document.querySelector('.btn-login-signup')?.classList.remove('inactive')
		document.querySelector('.profile-greeting')?.classList.add('inactive')
	}
}

// export function generateSubscribedString() {
// 	const user = cookieUserData()
// 	if (!user) { return 'anonymous' }
// 	return user.isStaff === true
// 		? 'staff'
// 		: user.subscribed === true
// 			? 'subscribed'
// 			: 'account'
// }

export default { init, get, checkLoginUser }
